.container {
	margin: 0;
	display: flex !important;
	flex-direction: column;
	align-items: center;
}

.logo {
	max-width: 20rem;
	height: fit-content;
	margin-bottom: 48px
}

.form-grid {
	display: grid;
	width: 100%;
	gap: 30px;
	margin-bottom: 16px;
	justify-items: center;
	justify-self: center;
	width: 50%;
}

.form-grid input,
select {
	width: 100%;
}


form {
	width: 100%;
	display: grid;
}

.button-box {
	display: flex;
	gap: 16px;
	justify-self: center;
	width: 300px;
	margin-top: 16px;
}

.form-grid h2 {
	font-family: 'Poppins Regular';
	font-size: medium;
	text-align: left;
	justify-self: left;
	margin-bottom: -20px;
	color: #0e4b3e;
	width: auto;
}