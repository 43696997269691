.container-tickets-report {
	display: flex;
	gap: 30px;
	font-family: 'Poppins Regular';
	/* align-items: center; */
}

.cardImg {
	height: 500px;
}

.cardImgCom {
	height: 500px;
	width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 8px;
	border: 2px dashed #c6c6c6;
	border-radius: 16px;
}

.cardImgCom img {
	border-radius: 16px;
	height: 600px;
	width: 220px;
	object-fit: cover;
	/* scale: 0.5; */
	transform: rotate(-90deg);
}

.cardImg img {
	height: 480px;
	border-radius: 16px;
}

.moscaDemais b {
	color: black
}

.grid-card {
	display: grid;
	gap: 1px;
	background-color: #0e4b3e82;
	padding: 1px;
}

.infos {
	font-family: 'Poppins Regular';
	display: grid;
	color: black;
}

.grid-dados {
	display: grid;
	margin-left: 16px;
	color: black;
}

.grid-card .card {
	background-color: #f0f0f0;
}

.titleSerasa {
	font-family: 'Poppins Regular';
	color: black;
}

.container-reports {
	display: flex;
}

.container-serasa {
	width: 50%;
}

.container-serasa h2 {
	width: auto;
	font-size: 18px;
	margin-top: 14px;
	margin-bottom: 8px;
}

.container-serasa .grid-card {
	font-size: 15px;
}

.warning {
	font-family: 'Poppins Regular';
	font-size: 16px;
	color: black;
}

.container-a {
	display: block !important;
}

.select-bank-ul {
	margin-top: 4px;


}


#buttons-container {
	position: sticky;
	bottom: 0;
}

.bank-account-info {
	display: grid;
	grid-template-columns: 1fr 0.5fr;
	gap: 8px;
}

.bank-account-type {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
}


#input-radio {
	margin-bottom: 0;
}

#default-inputs {
	padding: 0;
	margin-top: 8px;
}

h3 {
	margin-bottom: 0px;
}

.select-bank-ul {
	background-color: #FFFFFF;
	list-style: none;
	border-radius: 16px;
	padding-inline-start: 18px;
	max-height: 100px !important;
	display: grid;
	gap: 5px;
	overflow: scroll;
	padding: 10px 10px;
}

.bank-account-type {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 8px;
}

.select-bank-li {
	border-radius: 0;
	font-size: 0.6em;
	padding: 0;
	padding-left: 10px;
	height: 33px;

}