#input-radio {
    list-style-type: none;
    background-color: var(--nyc-components-background);
    border-radius: 16px;
    accent-color: var(--nyc-green-400);
    height: 53px;
    margin: 12px 0;


}

#input-radio label {
    display: flex;
    height: 53px;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;


}

#input-radio label input {
    width: 17px;
    height: 17px;
}