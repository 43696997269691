:root {
	--nyc-green-100: #15f987;
	--nyc-green-200: #07bb02;
	--nyc-green-250: #07ba01;
	--nyc-green-400: #0e4b3e;
	--nyc-purple: #8753f0;
	--nyc-black-200: #202020;
	--nyc-gray: #b4b4b4;
	--nyc-black-100: #000000;
	--nyc-white-100: #f9f9f9;
	--nyc-white-200: #ffffff;
	--nyc-pink: #ef4d8e;
	--nyc-yellow: #f5ba19;
	--nyc-red: #b20505;

	--max-width: 1100px;
	--border-radius: 12px;
	--font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
		'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
		'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;

	--primary-glow: conic-gradient(from 180deg at 50% 50%,
			#16abff33 0deg,
			#0885ff33 55deg,
			#54d6ff33 120deg,
			#0071ff33 160deg,
			transparent 360deg);
	--secondary-glow: radial-gradient(rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0));

	--tile-start-rgb: 239, 245, 249;
	--tile-end-rgb: 228, 232, 233;
	--tile-border: conic-gradient(#00000080,
			#00000040,
			#00000030,
			#00000020,
			#00000010,
			#00000010,
			#00000080);

	--callout-rgb: 238, 240, 241;
	--callout-border-rgb: 172, 175, 176;
	--card-rgb: 180, 185, 188;
	--card-border-rgb: 131, 134, 135;
}

@font-face {
	font-family: "Poppins SemiBold";
	src: local("Poppins"), local("Poppins-SemiBold"),
		url("../src/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins Medium";
	src: local("Poppins"), local("Poppins-Medium"),
		url("../src/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins Bold";
	src: local("Poppins"), local("Poppins-Bold"),
		url("../src/fonts/Poppins-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Poppins Regular";
	src: local("Poppins"), local("Poppins-Regular"),
		url("../src/fonts/Poppins-Regular.ttf") format("truetype");
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;

		--primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
		--secondary-glow: linear-gradient(to bottom right,
				rgba(1, 65, 255, 0),
				rgba(1, 65, 255, 0),
				rgba(1, 65, 255, 0.3));

		--tile-start-rgb: 2, 13, 46;
		--tile-end-rgb: 2, 5, 19;
		--tile-border: conic-gradient(#ffffff80,
				#ffffff40,
				#ffffff30,
				#ffffff20,
				#ffffff10,
				#ffffff10,
				#ffffff80);

		--callout-rgb: 20, 20, 20;
		--callout-border-rgb: 108, 108, 108;
		--card-rgb: 100, 100, 100;
		--card-border-rgb: 200, 200, 200;
	}
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

#root {
	display: flex;
	justify-content: center;
	overflow: hidden;
	height: 100vh;
}


html,
body {
	max-width: 100vw;
	overflow: hidden;
	background-color: #f9f9f9;

}

main {
	overflow-y: scroll;
	height: 100vh;
}

a {
	color: inherit;
	text-decoration: none;
}

@media (prefers-color-scheme: dark) {
	html {
		color-scheme: dark;
	}
}

.qrcodeContainer {
	overflow-y: auto !important;
}

.dt-search *,
.dt-paging *,
.dt-paging-button {
	color: #000 !important;
}